(function (w) {
    w.addEventListener("load", function () {
        function loadCallback(callback) {
            if (typeof loadRecaptcha === "function" && typeof w[callback] === "function") {
                setTimeout(function () {
                    loadRecaptcha(callback);
                }, 5000);
            }
        }

        loadCallback("onloadCallbackRappelRdv");
        loadCallback("onloadCallbackRappel");
        loadCallback("onloadCallbackNewsletter");
    });
})(window);

(function ($, d) {
  if ("undefined" === typeof TEMPLATE_DIRECTORY_URI) {
    var TEMPLATE_DIRECTORY_URI = "wp-content/themes/ayn";
  }

  var owl = $(".ayn-slider");
  var changeBackground = function (el) {
    var backgroundUrl = $(el).data("background-url");
    $(el)
      .css("backgroundImage", backgroundUrl)
      .removeAttr("data-background-url");
  };
  owl.owlCarousel({
    animateOut: "fadeOut",
    loop: true,
    items: 1,
    mouseDrag: false,
    slideTransition: 1000,
    nav: true,
    dots: true,
    lazyLoad: true,
    onTranslate: function () {
      owl.find("[data-background-url]").each(function () {
        changeBackground(this);
      });
    },
    // Setup animations
    onChanged: function (event) {
      if (event.item.index) {
        var item = event.item.index - 2;
        var slider = $(event.currentTarget);
        var parentItem = slider.find(".owl-item").not(".cloned").eq(item);

        slider.find(".ayn-title").removeClass("slideUp");
        slider.find(".content-description").removeClass("slideUp");
        slider.find(".content-btn").removeClass("fadeAnimateIn");
        slider.find(".img-slider").removeClass("slideRight");

        parentItem.find(".ayn-title").addClass("slideUp");
        parentItem.find(".content-description").addClass("slideUp");
        parentItem.find(".content-btn").addClass("fadeAnimateIn");
        parentItem.find(".img-slider").addClass("slideRight");
      }
    }
  });
})(jQuery, document);

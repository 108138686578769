$(function () {
    var EQUALIZE_THESE_CLASS = ["equalizer-1", "equalizer-2", "equalizer-3", "equalizer-4", "equalizer-5", "equalizer-6", "equalizer-7", "equalizer-8", "equalizer-9", "equalizer-10", "equalizer-11", "equalizer-12", "equalizer-13", "equalizer-14",
        "equalizer-t1", "equalizer-t2", "equalizer-t3", "equalizer-t4", "equalizer-t5", "equalizer-t6", "equalizer-t7", "equalizer-t8", "equalizer-t9", "equalizer-t10", "equalizer-actu-paragraph", "equalizer-actu-title", "equalizer-title-ccm", "equalizer-desc-ccm", "equalizer-owl", "img-courbe"];

    Array.prototype.equalizeElementHeight = function () {
        for (var i = 0, length = EQUALIZE_THESE_CLASS.length; i < length; i++) {
            var className = EQUALIZE_THESE_CLASS[i];
            var tj = 0;
            $("." + className).each(function (i, e) {
                tj = Math.max($(e).height(), tj);
            });
            $("." + className).css('height', tj);
        }
    }
    function initElementEqualizer() {
        EQUALIZE_THESE_CLASS.equalizeElementHeight();
    }

    setTimeout(initElementEqualizer, 500); // pour une meilleure résultat, placer cette ligne dans windows.load()
});



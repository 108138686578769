$(function () {
	$(window).on("scroll", function () {
		var distance = $(document).scrollTop();
		var header = $("header");
		var menuBar = $(".menu-bar");
		if (!distance) {
			header.removeClass('sticky');
			menuBar.off().removeClass('reveal');
		} else {
			header.addClass('sticky');
			menuBar.addClass('reveal');
		}
	});

	$(".hamburger").on("click", function () {
		$(this).toggleClass("active");
		$("#main-menu").toggleClass('visible');
	});

	/* Insertion du lien vers page parent sur le BC*/
	var parentElem = $('.menu-bar li.current-menu-parent');
	if (parentElem.length) {
		var parentLink = parentElem.find('a')[0].outerHTML;
		$(parentLink).append(' / ').insertBefore('.breadcrumb_last');
	}

	//Current Best Practice
	if ($(".single-best_practice").length) set_current_menu_nav("Ressources", "Best practices", "Resources", "Best practices");

	//Current Enquête en ligne
	if ($(".single-etape").length) set_current_menu_nav("Ressources", "Enquête en ligne", "Resources", "Guide to sucessful online survey");

	//Current Cas clients
	if ($(".single-cas_client").length) set_current_menu_nav("Ressources", "Cas clients", "Resources", "Case study");

	//Current Recrutement
	if (jQuery(".single-annonce").length)  set_current_menu_nav("à propos","Recrutement","About","Recruitment");
});

function set_current_menu_nav(parent_menu_fr, child_menu_fr, parent_menu_en, child_menu_en) {
	var parent_menu = parent_menu_fr;
	var child_menu = child_menu_fr;
	var main_menu = "menu-main-menu";
	if ($("#menu-main-menu-anglais").length) {
		parent_menu = parent_menu_en;
		child_menu = child_menu_en;
		main_menu = "menu-main-menu-anglais";
	}

	$("#" + main_menu + ">li>a:contains('" + parent_menu + "')").parent().addClass("current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor");
	$("#" + main_menu + " .sub-menu>li>a:contains('" + child_menu + "')").parent().addClass("current-menu-item page_item current_page_item");
}

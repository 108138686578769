$(function () {
  $('.owl-carousel-ccmarche-1').owlCarousel({
    items: 3,
    autoplay: true,
    nav: false,
    dots: true,
    responsive: {
      // breakpoint from 0 up
      0: {
        items: 1,
      },
      // breakpoint from 480 up
      480: {
        items: 1,
      },
      // breakpoint from 768 up
      992: {
        items: 2,
      },
      1280: {
        items: 3,
      }
    }
  });

  $('.owl-carousel-dmappli-1').owlCarousel({
    items: 3,
    autoplay: true,
    nav: false,
    dots: true,

    responsive: {
      // breakpoint from 0 up
      0: {
        items: 1,
      },
      // breakpoint from 480 up
      480: {
        items: 1,
      },
      // breakpoint from 768 up
      992: {
        items: 2,
      },
      1280: {
        items: 3,
      }
    }
  });

  $('.owl-carousel-dmappli-2').owlCarousel({
    items: 4,
    autoplay: true,
    nav: false,
    dots: true,

    responsive: {
      // breakpoint from 0 up
      0: {
        items: 1,
      },
      // breakpoint from 480 up
      480: {
        items: 1,
      },
      // breakpoint from 768 up
      992: {
        items: 3,
      },
      1280: {
        items: 4,
      }
    }
  });

  $('.owl-carousel-actus-conseils').owlCarousel({
    items: 1,
    autoplay: true,
    nav: false,
    dots: true,
    autoplayTimeout: 15000,
    autoplayHoverPause: true,
    loop: true,
    smartSpeed: 600,
    responsive: {
      // breakpoint from 0 up
      0: {
        items: 1,
      },
      // breakpoint from 480 up
      480: {
        items: 1,
      },
      // breakpoint from 768 up
      992: {
        items: 2,
      },
      1280: {
        items: 3,
      }
    }
  });

  $('.owl-carousel-exp-areyounet').owlCarousel({
    items: 1,
    autoplay: true,
    nav: false,
    dots: true,
    autoplayTimeout: 4000,
    animateIn: 'fadeIn',
    animateOut: 'fadeOut',
    autoplayHoverPause: true,
    loop: true,
    smartSpeed: 600,
    dotsSpeed: 1000,
    responsive: {
      // breakpoint from 0 up
      0: {
        items: 1,
      },
      // breakpoint from 480 up
      480: {
        items: 1,
      },
      // breakpoint from 768 up
      992: {
        items: 1,
      },
      1280: {
        items: 1,
      }
    }
  });

  $('.owl-carousel-exp-areyounet-conseil').owlCarousel({
    items: 1,
    autoplay: true,
    nav: false,
    dots: true,

    responsive: {
      // breakpoint from 0 up
      0: {
        items: 1,
      },
      // breakpoint from 480 up
      480: {
        items: 1,
      },
      // breakpoint from 768 up
      992: {
        items: 1,
      },
      1280: {
        items: 1,
      }
    }
  });

  $('.owl-carousel-exp-areyounet-conseil-col-deux').owlCarousel({
    items: 2,
    autoplay: true,
    nav: false,
    dots: true,

    responsive: {
      // breakpoint from 0 up
      0: {
        items: 1,
      },
      // breakpoint from 480 up
      480: {
        items: 1,
      },
      // breakpoint from 768 up
      992: {
        items: 1,
      },
      1280: {
        items: 2,
      }
    }
  });

  $('.owl-carousel-exp-areyounet-conseil-col-three').owlCarousel({
    items: 3,
    autoplay: true,
    nav: false,
    dots: true,

    responsive: {
      // breakpoint from 0 up
      0: {
        items: 1,
      },
      // breakpoint from 480 up
      480: {
        items: 1,
      },
      // breakpoint from 768 up
      992: {
        items: 2,
      },
      1280: {
        items: 3,
      }
    }
  });

  $('.owl-carousel-exp-areyounet-conseil-col-four').owlCarousel({
    items: 4,
    autoplay: true,
    nav: false,
    dots: true,

    responsive: {
      // breakpoint from 0 up
      0: {
        items: 1,
      },
      // breakpoint from 480 up
      480: {
        items: 1,
      },
      // breakpoint from 768 up
      992: {
        items: 2,
      },
      1280: {
        items: 4,
      }
    }
  });


  $('.owl-carousel-references').owlCarousel({
    items: 5,
    autoplay: true,
    nav: false,
    dots: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    loop: true,
    smartSpeed: 300,


    responsive: {
      // breakpoint from 0 up
      0: {
        items: 1,
      },
      // breakpoint from 480 up
      480: {
        items: 2,
      },
      // breakpoint from 768 up
      992: {
        items: 4,
      },
      1280: {
        items: 5,
      }
    }
  });


});

/**
 * Google tag manager
 *
 * Evénement pour les formulaires de démo et contact
 */
(function (w, d) {
    var DEMO_FORM_ID = 316;
    var CONTACT_FORM_ID = 476;
    w.addEventListener("DOMContentLoaded", function () {
        if ("object" === typeof dataLayer) {
            d.addEventListener('wpcf7submit', function (event) {
                switch (parseInt(event.detail.contactFormId)) {
                    // Formulaire de démo
                    case DEMO_FORM_ID:
                        dataLayer.push({ "event": "demo" });
                        break;

                    // Formulaire de contact
                    case CONTACT_FORM_ID:
                        dataLayer.push({ "event": "contact" });
                }
            }, false);
        }
    });
})(window, document);
